<template>
  <v-container>
    <v-row style="max-width;1300px:">
      <v-col cols="9" ref="element">
        <v-row class="part1">
          <v-col cols="12">
            <v-system-bar window color="#f3f3f3">
              <v-icon>mdi-dip-switch</v-icon>
              <span>模块名称</span>
              <v-spacer></v-spacer>
              <v-btn plain small >
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn plain small >
              <v-icon>mdi-close</v-icon>
            </v-btn>
              
            </v-system-bar>
          </v-col>
          <v-col cols="5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Calories</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in desserts" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="1" style="display: flex; align-items: center">
            <div>
              <v-btn width="26" class="px-0" outlined>
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
              <v-btn class="px-0" outlined style="margin-top: 15px">
                <v-icon> mdi-chevron-double-left </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Calories</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in desserts" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="part2">
          <v-col
            class="pt-0 pb-0"
            style="height: 40px; display: flex; align-items: center"
          >
            <v-checkbox label="自动发送"></v-checkbox>
          </v-col>
          <v-col
            class="pt-0 pb-0"
            style="
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <v-btn @click="clickHelpCard()" outlined small>
              帮助说明<v-icon></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="part3">
          <v-col>
            <v-btn-toggle>
              <v-btn small>
                <v-icon>mdi-file-question-outline</v-icon>
              </v-btn>

              <v-btn small>
                <v-icon>mdi-file-table</v-icon>
              </v-btn>
              <v-btn small>
                <v-icon>mdi-import</v-icon>
              </v-btn>

              <v-btn small>
                <v-icon>mdi-export</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <v-btn small> 取消<v-icon></v-icon> </v-btn>
            <v-btn class="ms-3" color="primary" small>
              确定<v-icon></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-show="help_show" cols="3" style="display: flex">
        <v-row
          color="blue-grey darken-3"
          style="margin-top: 100px; background-color: #465161"
        >
          <v-col
            cols="12"
            style="display: flex; justify-content: flex-end; height: 52px"
          >
            <v-btn @click="clickHelpCard()" plain small color="white">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-col>

          <v-col ref="help_card" cols="12" class="overflow-auto">
            <v-card color="#385F73" dark>
              <v-card-title class="text-h5"> 功能介绍： </v-card-title>

              <v-card-subtitle>
                测试测试测试测试测试测试测试测试测试
              </v-card-subtitle>
            </v-card>

            <v-card class="mt-2" color="#385F73" dark>
              <v-card-title class="text-h5"> 功能介绍： </v-card-title>

              <v-card-subtitle>
                测试测试测试测试测试测试测试测试测试
              </v-card-subtitle>
            </v-card>
            <v-card class="mt-2" color="#385F73" dark>
              <v-card-title class="text-h5"> 功能介绍： </v-card-title>

              <v-card-subtitle>
                测试测试测试测试测试测试测试测试测试
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "index",
  data: () => ({
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        name: "Eclair",
        calories: 262,
      },
      {
        name: "Cupcake",
        calories: 305,
      },
      {
        name: "Gingerbread",
        calories: 356,
      },
      {
        name: "Jelly bean",
        calories: 375,
      },
      {
        name: "Lollipop",
        calories: 392,
      },
      {
        name: "Honeycomb",
        calories: 408,
      },
      {
        name: "Donut",
        calories: 452,
      },
      {
        name: "KitKat",
        calories: 518,
      },
    ],
    help_show: false,
  }),
  mounted() {},
  methods: {
    clickHelpCard() {
      let height = this.$refs.element.offsetHeight;

      this.$refs.help_card.style["height"] = `${height - 160}px`;
      this.help_show = !this.help_show;
    },
  },
};
</script>

<style scoped>
.part1 {
  max-width: 1000px;
  margin-top: 100px;
  background-color: #f3f3f3;
}
.part2 {
  max-width: 1000px;
  background-color: #f3f3f3;
}
.part3 {
  max-width: 1000px;
  background-color: #f3f3f3;
}
</style>