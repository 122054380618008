<template>
  <v-app>
 
    <v-container>
  <v-tabs>
    <v-tab to="/">基础模板</v-tab>
    <!-- <v-tab to="/core">实验岩心测试数据去重处理</v-tab> -->
    <v-tab to="/test">实验数据去重处理</v-tab>
    <v-tab to="/las">测井资料标准化自动处理</v-tab>
    <v-tab to="/drill">钻井资料标准化自动处理</v-tab>
    <v-tab to="/core2">岩心自动归位</v-tab>
    <!-- <v-tab to="/stratum">层段特征提取</v-tab> -->
    <v-tab to="/algorithm">基于相关系数的层次聚类算法</v-tab>
    <v-tab to="/algorithm1">离散数据重叠度关系分析算法</v-tab>
    <v-tab to="/algorithm2">基于网格密度重叠度的层次聚类算法</v-tab>
    <v-tab to="/algorithm3">基于重叠度的事件概率差算法</v-tab>
    <v-tab to="/algorithm4">基于距离的层次聚类算法</v-tab>
    <v-tab to="/page1">机器学习智能分类决策系统</v-tab>
    <v-tab to="/page2">模型保存</v-tab>
    <v-tab to="/page3">层段信息自动提取</v-tab>
    <v-tab to="/page4">直井智能选层优化设计</v-tab>
    <v-tab to="/page5">智能聚类</v-tab>
    <v-tab to="/page6">水平井段簇优化设计(先用，后期还要改造)</v-tab>
    <v-tab to="/page7">机器学习自动化回归系统模型评估</v-tab>
    <v-tab to="/page8">机器学习模型应用</v-tab>
    <v-tab to="/page9">异常值自动处理</v-tab>
    <v-tab to="/page10">协方差选择</v-tab>
    <v-tab to="/page11">特征自动选择方法</v-tab>
    <v-tab to="/page12">特征降维处理</v-tab>
    <v-tab to="/page13">交叉协方差选择</v-tab>
    <v-tab to="/page14">微地震与压裂段数据链接</v-tab>
    <v-tab to="/page15">生产监测与压裂段数据链接</v-tab>
    <v-tab to="/page16">钻测录与压裂段数据链接</v-tab>
    <v-tab to="/page17">信号智能滤波处理</v-tab>
    <v-tab to="/page18">微观孔隙结构参数提取</v-tab>
    <v-tab to="/page19">粒度曲线特征参数提取</v-tab>
    <v-tab to="/page20">相渗曲线相渗参数提取</v-tab>
    <v-tab to="/page21">相关聚类分析</v-tab>
    <v-tab to="/page22">数据加载</v-tab>
    <v-tab to="/page23">数据属性划分</v-tab>
    <v-tab to="/page24">多地震属性合并拼接</v-tab>
    <v-tab to="/page25">地震属性与水平井压裂段数据链接</v-tab>
    <v-tab to="/page26">地震属性与直井压裂段数据链接</v-tab>
    <v-tab to="/page27">数据归一化</v-tab>
    <v-tab to="/page28">曲线重采样</v-tab>
    <v-tab to="/page29">图像识别数据大表生成</v-tab>
    <v-tab to="/page30">地震层序地层数据大表生成</v-tab>
    <v-tab to="/page31">地震层位智能解释回归预测数据大表</v-tab>
    <v-tab to="/page32">地震层位识别数据大表生成</v-tab>
    <v-tab to="/page33">DTW地层自动对比</v-tab>
    <v-tab to="/page34">分层数据格式转换</v-tab>
    <v-tab to="/page35">深度学习图像识别智能决策系统</v-tab>
    <v-tab to="/page36">深度学习结构化数据回归智能决策系统</v-tab>
    <!-- <v-tab to="/page37">分段岩性标注测井数据</v-tab> -->
    <v-tab to="/page38">层段与测录钻数据链接</v-tab>
    <v-tab to="/page39">岩性识别数据分类异常数据处理</v-tab>
    <v-tab to="/page40">数据筛选器</v-tab>
    <v-tab to="/page41">层段与大表数据链接</v-tab>
    <v-tab to="/page42">机械比能参数重构</v-tab>
    <v-tab to="/page43">数据合并</v-tab>
    <v-tab to="/page44">数据拆分</v-tab>
    <v-tab to="/page45">数据类别处理</v-tab>
    <v-tab to="/page46">各类特征分析</v-tab>
    <v-tab to="/page47">各类型单特征智能排序</v-tab>
    <v-tab to="/page48">数据统计汇总</v-tab>
    <v-tab to="/page49">离散点与曲线数据连接</v-tab>
    <v-tab to="/page50">离散点与层段数据链接</v-tab>
    <v-tab to="/page51">离散点与射孔数据链接</v-tab>
    <v-tab to="/page52">压裂段与射孔数据链接</v-tab>
    <v-tab to="/page53">压裂段与固井评价数据链接</v-tab>
    <v-tab to="/page54">井点处裂缝平面属性提取</v-tab>
  </v-tabs>

    </v-container>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
