import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
// import las from './views/las'
// import test from './views/test'
// import core from './views/core'
// import core2 from './views/core2'
// import algorithm from './views/algorithm'
// import algorithm1 from './views/algorithm1'
// import algorithm2 from './views/algorithm2'
// import algorithm3 from './views/algorithm3'
// import page1 from './views/page1'
// import page2 from './views/page2'
// import page3 from './views/page3'
// import page4 from './views/page4'
// import page5 from './views/page5'
// import page6 from './views/page6'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: index,
  },
  {
    path: "/las",
    name: "测井资料标准化自动处理",
    component: () =>
      import("../views/las.vue"),
  },
  {
    path: "/drill",
    name: "钻井资料标准化自动处理",
    component: () =>
      import("../views/drill.vue"),
  },
  {
    path: "/test",
    name: "实验数据去重处理",
    component: () =>
      import("../views/test.vue"),
  },
  {
    path: "/core",
    name: "实验岩心测试数据去重处理",
    component: () =>
      import("../views/core.vue"),
  },
  {
    path: "/core2",
    name: "岩心自动归位",
    component: () =>
      import("../views/core2.vue"),
  },

  {
    path: "/stratum",
    name: "层段特征提取",
    component: () =>
      import("../views/stratum.vue"),
  },
  {
    path: "/algorithm",
    name: "基于相关系数的层次聚类算法",
    component: () =>
      import("../views/algorithm.vue"),
  },
  {
    path: "/algorithm1",
    name: "基于占位符的层次聚类算法",
    component: () =>
      import("../views/algorithm1.vue"),
  },

  {
    path: "/algorithm2",
    name: "基于网格密度重叠度的层次聚类算法",
    component: () =>
      import("../views/algorithm2.vue"),
  },

  {
    path: "/algorithm3",
    name: "概率差分算法",
    component: () =>
      import("../views/algorithm3.vue"),
  },

  {
    path: "/algorithm4",
    name: "基于距离的层次聚类算法",
    component: () =>
      import("../views/algorithm4.vue"),
  },
  {
    path: "/page1",
    name: "机器学习智能分类决策系统",
    component: () =>
      import("../views/page1.vue"),
  },
  {
    path: "/page2",
    name: "模型保存",
    component: () =>
      import("../views/page2.vue"),
  },
  {
    path: "/page3",
    name: "层段信息自动提取",
    component: () =>
      import("../views/page3.vue"),
  },
  {
    path: "/page4",
    name: "直井智能选层优化设计",
    component: () =>
      import("../views/page4.vue"),
  },
  {
    path: "/page5",
    name: "智能聚类算法",
    component: () =>
      import("../views/page5.vue"),
  },
  {
    path: "/page6",
    name: "水平井段簇优化设计",
    component: () =>
      import("../views/page6.vue"),
  },
  {
    path: "/page7",
    name: "机器学习自动化回归系统模型评估",
    component: () =>
      import("../views/page7.vue"),
  },
  {
    path: "/page8",
    name: "机器学习模型应用",
    component: () =>
      import("../views/page8.vue"),
  },
  {
    path: "/page9",
    name: "异常值自动处理",
    component: () =>
      import("../views/page9.vue"),
  },
  {
    path: "/page10",
    name: "协方差选择",
    component: () =>
      import("../views/page10.vue"),
  },
  {
    path: "/page11",
    name: "特征自动选择方法",
    component: () =>
      import("../views/page11.vue"),
  },
  {
    path: "/page12",
    name: "特征降维处理",
    component: () =>
      import("../views/page12.vue"),
  },
  {
    path: "/page13",
    name: "交叉协方差选择",
    component: () =>
      import("../views/page13.vue"),
  },
  {
    path: "/page14",
    name: "微地震与压裂段数据链接",
    component: () =>
      import("../views/page14.vue"),
  },
  {
    path: "/page15",
    name: "示踪剂与压裂段数据链接",
    component: () =>
      import("../views/page15.vue"),
  },
  {
    path: "/page16",
    name: "钻测录与压裂段数据链接",
    component: () =>
      import("../views/page16.vue"),
  },
  {
    path: "/page17",
    name: "信号智能滤波处理",
    component: () =>
      import("../views/page17.vue"),
  },
  {
    path: "/page18",
    name: "微观孔隙结构参数提取",
    component: () =>
      import("../views/page18.vue"),
  },
  {
    path: "/page19",
    name: "粒度曲线特征参数提取",
    component: () =>
      import("../views/page19.vue"),
  },
  {
    path: "/page20",
    name: "相渗曲线相渗参数提取",
    component: () =>
      import("../views/page20.vue"),
  },
  {
    path: "/page21",
    name: "智能聚类",
    component: () =>
      import("../views/page21.vue"),
  },
  {
    path: "/page22",
    name: "数据加载",
    component: () =>
      import("../views/page22.vue"),
  },
  {
    path: "/page23",
    name: "数据属性划分",
    component: () =>
      import("../views/page23.vue"),
  },
  {
    path: "/page24",
    name: "多地震属性合并拼接",
    component: () =>
      import("../views/page24.vue"),
  },
  {
    path: "/page25",
    name: "地震属性与水平井压裂段数据链接",
    component: () =>
      import("../views/page25.vue"),
  },
  {
    path: "/page26",
    name: "地震属性与直井压裂段数据链接",
    component: () =>
      import("../views/page26.vue"),
  },
  {
    path: "/page27",
    name: "数据归一化",
    component: () =>
      import("../views/page27.vue"),
  },
  {
    path: "/page28",
    name: "曲线重采样",
    component: () =>
      import("../views/page28.vue"),
  },
  {
    path: "/page29",
    name: "图像识别数据大表制作",
    component: () =>
      import("../views/page29.vue"),
  },
  {
    path: "/page30",
    name: "地震层序地层数据大表生成",
    component: () =>
      import("../views/page30.vue"),
  },
  {
    path: "/page31",
    name: "地震层位智能解释回归预测数据大表",
    component: () =>
      import("../views/page31.vue"),
  },
  {
    path: "/page32",
    name: "地震层位识别数据大表生成",
    component: () =>
      import("../views/page32.vue"),
  },
  {
    path: "/page33",
    name: "DTW地层自动对比",
    component: () =>
      import("../views/page33.vue"),
  },
  {
    path: "/page34",
    name: "分层数据格式转换",
    component: () =>
      import("../views/page34.vue"),
  },
  {
    path: "/page35",
    name: "aa",
    component: () =>
      import("../views/page35.vue"),
  },
  {
    path: "/page36",
    name: "aa",
    component: () =>
      import("../views/page36.vue"),
  },
  {
    path: "/page37",
    name: "aa",
    component: () =>
      import("../views/page37.vue"),
  },
  {
    path: "/page38",
    name: "aa",
    component: () =>
      import("../views/page38.vue"),
  },
  {
    path: "/page39",
    name: "39",
    component: () =>
      import("../views/page39.vue"),
  },
  {
    path: "/page40",
    name: "40",
    component: () =>
      import("../views/page40.vue"),
  },
  {
    path: "/page41",
    name: "41",
    component: () =>
      import("../views/page41.vue"),
  },
  {
    path: "/page42",
    name: "42",
    component: () =>
      import("../views/page42.vue"),
  },
  {
    path: "/page43",
    name: "43",
    component: () =>
      import("../views/page43.vue"),
  },
  {
    path: "/page44",
    name: "44",
    component: () =>
      import("../views/page44.vue"),
  },
  {
    path: "/page45",
    name: "45",
    component: () =>
      import("../views/page45.vue"),
  },
  {
    path: "/page46",
    name: "46",
    component: () =>
      import("../views/page46.vue"),
  },
  {
    path: "/page47",
    name: "47",
    component: () =>
      import("../views/page47.vue"),
  },
  {
    path: "/page48",
    name: "48",
    component: () =>
      import("../views/page48.vue"),
  },
  {
    path: "/page49",
    name: "49",
    component: () =>
      import("../views/page49.vue"),
  },
  {
    path: "/page50",
    name: "50",
    component: () =>
      import("../views/page50.vue"),
  },
  {
    path: "/page51",
    name: "51",
    component: () =>
      import("../views/page51.vue"),
  },
  {
    path: "/page52",
    name: "52",
    component: () =>
      import("../views/page52.vue"),
  },
  {
    path: "/page53",
    name: "53",
    component: () =>
      import("../views/page53.vue"),
  },
  {
    path: "/page54",
    name: "54",
    component: () =>
      import("../views/page54.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
